import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

import { nord0, nord7 } from 'utils/colors';

const NotificationBar = styled.div`
  align-items: center;
  background-color: ${nord7};
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.4);
  color: ${nord0};
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  text-align: center;
  white-space: pre-wrap;
`;

const DismissButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${nord0};
  cursor: pointer;
  margin-left: 0.75rem;
  outline: none;
  padding: 0;
  transform: scaleY(0.75);
`;

const Notification = ({ children, dismissible }) => {
  const elRef = useRef(document.createElement('div'));
  const rootElRef = useRef(document.getElementById('notifications-root'));

  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const el = elRef.current;
    const rootEl = rootElRef.current;
    rootEl.appendChild(el);
    return () => rootEl.removeChild(el);
  }, []);

  const handleDismissClick = () => setDismissed(true);

  if (dismissed) {
    return null;
  }

  return ReactDOM.createPortal(
    <NotificationBar>
      {children}
      {dismissible && (
        <DismissButton onClick={handleDismissClick}>X</DismissButton>
      )}
    </NotificationBar>,
    elRef.current,
  );
};

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  dismissible: PropTypes.bool,
};

Notification.defaultProps = {
  dismissible: false,
};

Notification.DismissButton = DismissButton;

export default Notification;
